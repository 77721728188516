<template>
  <div class="mb-3 mt-5 pt-3">
    <div class="row">
      <div
        :class="{
          [$style.card__register]: true,
          'w-50': true,
        }"
      >
        <div
          :class="{
            [$style.card__register__image]: true,
          }"
        >
          <img
            src="@/assets/images/logo.svg"
            width="200"
            height="63"
          />
        </div>

        <div class="d-flex justify-content-center align-items-center pbz-font subtitle-md-medium mt-5 mb-3 text-center" style="color: #1A1A1A !important">
          Request Timed Out
        </div>

        <div class="d-flex justify-content-center align-items-center pbz-font body-md-regular my-3 text-center" style="color: #999999 !important">
          This request takes too long to process, it is timed out by the server. Please re-login to your registered account.
        </div>

        <a-button
          size="large"
          class="pbz-font body-md-bold text-center w-100 mt-3"
          :class="{
            [$style.button__submit]: true,
          }"
          @click.prevent="() => toLoginPage()"
        >
          <div v-if="!loading">
            Redirect to Login Page
          </div>
          <div v-if="loading">
            <a-icon type="loading" /> Loading
          </div>
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
// import { types as dashboardMutatioTypes } from '@/store/dashboard/mutations'

export default {
  computed: {
    registerStatus () {
        Cookies.set('invite-user', null)
      // if (this.$store.state.user.typeRegister === 'email') {
        return 'Email Anda telah berhasil di verifikasi'
      // } else if (this.$store.state.user.typeRegister === 'phone') {
      //   return 'No. Telephone Anda telah berhasil di verifikasi'
      // } else {
        // return 'Verifikasi Berhasil'
      // }
    },
    loading () {
      return this.$store.state.user.loading
    },
  },
  mounted() {
  },
  methods: {
    toLoginPage () {
      this.$keycloak.logout()
      window.location.href = window.location.origin
    },
  },
}
</script>

<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
